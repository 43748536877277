// IMPORTS
@import (reference) "../settings.less";

.main-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--rowGap);
}

// MAIN BANNER
.main-banner {
    width: 100%;
    margin: 0;
    overflow: hidden;
    border-radius: var(--borderRadius);

    .swiper-slide {
        display: block;
        position: relative;
    }

    .banner-img-wrapper {
        aspect-ratio: 1090 / 400;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    b {
        position: absolute;
        color: white;
        font-size: 20px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 48px;
        width: fit-content;
        text-align: center;
        max-width: 90%;
        background-color: rgba(27, 39, 39, 0.9);
        padding: 13px 32px;
        border-radius: var(--borderRadius);
    }
}
